<template>
  <div>
    <p>
      © {{ new Date().getFullYear() }} Bio-Techne
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>