<template>
  <div
    v-for="(image, idx) in images.sort((a, b) => a.imageOrder - b.imageOrder)"
    :key="idx"
    class="pnl-div"
  >
    <Panel :header="image.legendTitle" style="margin-bottom: 25px">
      <template #icons>
        <Button
          @click="getImageData(image.imageGuid, 'Details')"
          class="p-button-text p-button-plain"
        >
          <i style="margin-right: 5px" class="pi pi-list"></i>
          Details
        </Button>
        <Button
          v-if="canWrite"
          @click="getImageData(image.imageGuid, 'Update')"
          class="p-button-text p-button-plain"
        >
          <i style="margin-right: 5px" class="pi pi-pencil"></i>
          Edit
        </Button>
      </template>
      <div class="p-grid">
        <div class="p-col-2">
          <img
            :src="image.fileLocation"
            :alt="image.legendTitle"
            class="pnl-content-img"
          />
        </div>
        <div class="p-col-10">
          <div class="p-grid">
            <div class="p-col-2">
              <div class="pnl-content-captions">File Name</div>
            </div>
            <div class="p-col-10">
              <div class="pnl-content-values">
                {{ image.fileName }}
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-2">
              <div class="pnl-content-captions">Legend Title</div>
            </div>
            <div class="p-col-10">
              <div class="pnl-content-values">
                {{ image.legendTitle }}
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-2">
              <div class="pnl-content-captions">Image Status</div>
            </div>
            <div class="p-col-10">
              <div class="pnl-content-values">
                {{ image.imageStatusGu.imageStatus }}
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-2">
              <div class="pnl-content-captions">Image Order</div>
            </div>
            <div class="p-col-10">
              <div class="pnl-content-values">
                {{ image.imageOrder }}
              </div>
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col-2">
              <div class="pnl-content-captions">Updated Date</div>
            </div>
            <div class="p-col-10">
              <div class="pnl-content-values">
                <DateTime :date-value="new Date(image.updatedDate)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import Panel from "primevue/panel";
import DateTime from "../base/BaseDateTime.vue";
import Button from "primevue/button";
import "primeflex/primeflex.css";
import AxiosService from "../../api/AxiosService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, toRef } from "vue";
import { hasWriteAccess } from "../../Okta/oktaHandler";

export default {
  name: "CategoryImagePanel",
  props: {
    imageArray: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Panel,
    DateTime,
    Button,
  },
  setup(props) {
    const images = toRef(props, "imageArray");
    const store = useStore();
    const router = useRouter();
    const canWrite = ref(false);

    hasWriteAccess().then(res => {
      canWrite.value = res;
    });

    const getImageData = async (imageGuid, navigateTo) => {
      const imageData = await AxiosService.getImageData(imageGuid);
      store.commit("categoryImages/setImageData", imageData);

      router.push({
        name: navigateTo,
      });
    };

    return { images, canWrite, getImageData };
  },
};
</script>

<style scoped>
.pnl-div {
  clear: both;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pnl-content-captions {
  font-style: italic;
  color: #a9a9a9;
  text-align: left;
  font-weight: bold;
}

.pnl-content-values {
  text-align: left;
}

.pnl-content-img {
  max-width: 120px;
  float: left;
  max-height: 160px;
}
</style>