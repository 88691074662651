<template>
  <span :class="dateClass">{{ date }}</span>
</template>

<script>
import { onUpdated, toRefs, ref } from "vue";

const allowedDateFormats = [
  "MMddyyyy",
  "ddMMyyyy",
  "yyyyMMdd",
  "ddMMMyyyy",
  "ddMMMMyyyy",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthsShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const allowedClockFormats = ["HH12", "HH24"];

export default {
  name: "BaseDateTime",
  props: {
    dateValue: {
      type: Date,
      default: () => new Date(),
    },
    dateClass: {
      type: Array,
      default: () => [],
    },
    dateFormat: {
      type: String,
      default: () => "MMddyyyy",
      validator: (value) => {
        return allowedDateFormats.includes(value);
      },
    },
    dateDelimiter: {
      type: String,
      default: () => "/",
    },
    displayTime: {
      type: Boolean,
      default: () => false,
    },
    clockFormat: {
      type: String,
      default: () => "HH12",
      validator: (value) => {
        return allowedClockFormats.includes(value);
      },
    },
  },
  setup(props) {
    const { dateValue, dateDelimiter, dateFormat, displayTime, clockFormat } =
      toRefs(props);
    const date = ref(
      constructDate(
        dateValue.value,
        dateDelimiter.value,
        dateFormat.value,
        displayTime.value,
        clockFormat.value
      )
    );
    onUpdated(() => {
      date.value = constructDate(
        dateValue.value,
        dateDelimiter.value,
        dateFormat.value,
        displayTime.value,
        clockFormat.value
      );
    });

    return { date };
  },
};

function constructDate(dt, delim, format, displayTime, clockFormat) {
  const m = ("0" + (dt.getMonth() + 1)).slice(-2);
  const d = ("0" + dt.getDate()).slice(-2);
  const y = dt.getFullYear();
  let date = "";
  switch (format) {
    case "ddMMyyyy":
      date = `${d}${delim}${m}${delim}${y}`;
      break;
    case "yyyyMMdd":
      date = `${y}${delim}${m}${delim}${d}`;
      break;
    case "ddMMMyyyy":
      date = `${d} ${monthsShort[dt.getMonth()]}, ${y}`;
      break;
    case "ddMMMMyyyy":
      date = `${d} ${months[dt.getMonth()]}, ${y}`;
      break;
    default:
      date = `${m}${delim}${d}${delim}${y}`;
      break;
  }
  
  if (displayTime) {
    let time = "";
    switch (clockFormat) {
      case "HH24":
        time = dt.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit', second:'2-digit' });
        break;
      default:
        time = dt.toLocaleTimeString();
        break;
    }
    date = `${date} ${time}`;
  }
  return date;
}
</script>