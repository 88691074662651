<template>
  <Loading :is-loading="isBusy" loading-background="#FFFFFF" :loading-opacity="1" />
  <Toolbar>
    <template #left>
      <label>Category Images</label>
    </template>
    <template #right>
      <router-link to="/">
        <Button
          icon="pi pi-home"
          label="Home"
          class="p-button-text p-button-secondary p-mr-2"
        />
      </router-link>
      <router-link v-if="userInfo.hasWriteAccess" to="/create">
        <Button
          icon="pi pi-plus"
          label="New Image"
          class="p-button-text p-button-secondary p-mr-2"
        />
      </router-link>
      <div v-if="!isBusy">
        <SplitButton
          v-if="isAuthenticated"
          :label="userInfo.user.given_name"
          icon="pi pi-user"
          :model="userDropdown"
          class="p-button-text p-button-secondary p-mr-2"
        ></SplitButton>
      </div>
    </template>
  </Toolbar>
</template>
<script>
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import SplitButton from "primevue/splitbutton";
import { reactive, ref } from "vue";
import {
  oktaAuthClient,
  hasWriteAccess,
} from "../../Okta/oktaHandler";
import Loading from "../base/Loading.vue";

export default {
  name: "Header",
  components: {
    Toolbar,
    Button,
    SplitButton,
    Loading
  },
  setup() {
    const userInfo = reactive({
      user: {},
      groups: [],
      hasWriteAccess: false,
    });
    const isAuthenticated = ref(false);
    const userDropdown = ref([
      {
        label: "Logout",
        command: () => {
          if (isAuthenticated.value) {
            oktaAuthClient.signOut();
          }
        },
      },
    ]);
    const isBusy = ref(true);

    const setUser = async () => {
      userInfo.user = await oktaAuthClient.getUser();
      userInfo.groups = userInfo.user.groups ?? [];
      userInfo.hasWriteAccess = await hasWriteAccess(userInfo.groups);
    };

    setTimeout(async () => {
      try {
        isAuthenticated.value = await oktaAuthClient.isAuthenticated();
        if (isAuthenticated.value) {
          await setUser();
        }
      } finally {
        isBusy.value = false;
      }
    }, 1000);

    return { userInfo, isAuthenticated, userDropdown, isBusy };
  },
};
</script>
<style scoped>
.p-toolbar {
  margin-bottom: 25px;
  color: #777;
  font-family: Helvetica, sans-serif;
  padding: 0.5rem;
  font-size: 18px;
  border-radius: 0px;
}

.p-toolbar a {
  text-decoration: none;
}
</style>