import axios from "axios";

const validateApiUrl = (url) => {
    let isUrlValid = true;
    try {
        new URL(url);
    }
    catch (error) {
        isUrlValid = false;
        console.log(`Invalid Header API URL: ${url}`);
        console.log(error);
    }

    return isUrlValid;
};

const getApiClient = (url, headerKey, headerValue) => {
    return axios.create({
        baseURL: url,
        headers: {
            [headerKey]: headerValue
        }
    });
};

export { validateApiUrl, getApiClient };