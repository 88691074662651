import { catImagesApiClient } from './CategoryImagesApiClient';
import { oktaAuthClient } from '../Okta/oktaHandler';
import { headerApiClient } from './HeaderApiClient';

export default {

  async getAllBrands() {
    const res = await catImagesApiClient.get('/TlkBrands');
    return res.data;
  },

  async getCategories(brandName) {
    const res = await catImagesApiClient.get(`/Categories/CategorySearch/${brandName}`);
    return res.data;
  },

  async getImageTypes() {
    const res = await catImagesApiClient.get('/TlkImageTypes');
    return res.data;
  },

  async getImages(categoryName, brandName) {
    const res = await catImagesApiClient.get(`/BaseImages/GetSearchData/${categoryName}/${brandName}/1/50`);
    return res.data;
  },

  async getImageData(imageGuid) {
    const res = await catImagesApiClient.get(`/BaseImages/${imageGuid}`);
    return res.data;
  },

  async postImage(data) {
    const formData = await shapeImageData(data, false);

    var res = await catImagesApiClient.post("/baseimages",
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res.data;
  },

  async putImage(data) {
    const formData = await shapeImageData(data, true);

    var res = await catImagesApiClient.put(`/baseimages/${data.imageGuid}`,
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res.data.value;
  },

  // Region Common Header API Calls
  async getCommonHeader() {
    const res = await headerApiClient.get("/CommonHeader/CategoryImages");
    return res.data;
  }
  // End Region Common Header API Calls

}

async function shapeImageData(data, isUpdate) {
  const categories = data.imageCategories.map((el) => ({
    BrandGuid: data.brand.brandGuid,
    CategoryGuid: el.id
  }));
  const user = await oktaAuthClient.getUser();
  const baseImage = {
    LegendTitle: data.legendTitle,
    ImageAltText: data.imageAltText,
    ImageOrder: data.imageOrder,
    ImageStatusGu:
    {
      imageStatus: data.imageStatus ? "Active" : "Disabled"
    },
    ImageTypeGu:
    {
      ImageType: data.imageType
    },
    ImageCategory: categories,
    UpdatedbyUser: user.email ?? user.name
  };

  if (isUpdate) {
    baseImage.ImageGuid = data.imageGuid;
    baseImage.CreatedDate = data.createdDate;
  } else {
    baseImage.CreatedbyUser = user.email ?? user.name;
  }

  const formData = new FormData();
  if (data.imageFile) {
    baseImage.FileName = data.imageFile.name;
    formData.append("imageFile", data.imageFile);
  }
  formData.append("baseImage", JSON.stringify(baseImage));

  return formData;
}