<template>
  <div v-html="headerHtml"></div>
</template>

<script>
import { ref } from "vue";
import AxiosService from "@/api/AxiosService";
export default {
  name: "CommonHeader",
  setup() {
    const headerHtml = ref(null);

    AxiosService.getCommonHeader().then((res) => {
      headerHtml.value = res;
    });

    return { headerHtml };
  },
};
</script>
<style scoped>
</style>