import { createStore } from 'vuex';
import { categoryImages } from './modules/categoryImages';
import createPersistedState from "vuex-persistedstate";

const persistedState = createPersistedState({
  paths: [
    'categoryImages.imageData',
    'categoryImages.selectedBrand',
    'categoryImages.categories'
  ]
})

const store = createStore({
  modules: {
    categoryImages: categoryImages
  },
  plugins: [persistedState]
});

export default store;