export const categoryImages = {
    namespaced: true,
    state: {
        selectedBrand: null,
        selectedCategory: null,
        images: [],
        categories: [],
        imageData: null
    },
    getters: {
        getSelectedBrand: (state) => state.selectedBrand,
        getSelectedCategory: (state) => state.selectedCategory,
        getImages: (state) => state.images,
        getCategories: (state) => state.categories,
        getImageData: (state) => state.imageData
    },
    mutations: {
        setSelectedBrand: (state, brand) => state.selectedBrand = brand,
        setSelectedCategory: (state, category) => state.selectedCategory = category,
        setImages: (state, images) => state.images = images,
        setCategories: (state, categories) => state.categories = categories,
        setImageData: (state, imageData) => state.imageData = imageData
    },
    actions: {
    },
    modules: {
    }
}
