<template>
  <CommonHeader />
  <Header />
  <div class="app-container">
    <router-view />
  </div>
  <Footer />
</template>
<script>
import Header from "./components/header/Header.vue";
import Footer from "./components/footer/Footer.vue";
import CommonHeader from "./components/header/CommonHeader.vue";
export default {
  name: "app",
  components: {
    Header,
    Footer,
    CommonHeader,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app-container {
  margin-left: 1%;
  margin-right: 1%;
  min-height: 45vw;
}
</style>
