<template>
  <Button :label=name :icon=buttonIcon />
</template>

<script>
import Button from "primevue/button";
export default {
  name: "BaseButton",
  props: {
    name: {
      type: String,
      default: "I need a name! :-)",
    },
    buttonIcon: {
      type: String,
      default: "",
    },
  },
  components: {
    Button,
  },
};
</script>