<template>
  <div class="container">
    <h4>{{ toastTitle }}</h4>
    <p>{{ toastMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "Toastification",
  props: {
    toastTitle: {
      type: String,
      default: () => "Operation Failed",
    },
    toastMessage: {
      type: String,
      default: () => "An error occurred",
    },
  },
};
</script>