<template>
    <AutoComplete
        forceSelection 
        @complete="filterSuggestions()"
        @dropdown-click="userInput = null"
        :dropdown="true"
        dropdownMode="current"
        :field=displayField
        :placeholder=placeHolderText
        :suggestions="filteredSuggestions"
        v-model="userInput"
        @item-select="$emit('dropdown-selected',userInput)"
    />
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import {ref,toRefs} from 'vue';

export default {
    name: "BaseAutoCompleteDropDown",
    components: {
        AutoComplete
    },
    props: {
        placeHolderText: {
            type: String,
            default: ""
        },
        listOfSuggestions: {
            type: Array,
            default: () => [],
            required: true
        },
        displayField:{
            type: String,
            default: "",
            required: true
        }
    },
    setup(props){
        const { listOfSuggestions, displayField } = toRefs(props);

        const filteredSuggestions = ref(listOfSuggestions.value);
        const userInput = ref(null);

        const filterSuggestions = () => {
            setTimeout(() => {
                if (!userInput.value) {
                    filteredSuggestions.value = [...listOfSuggestions.value];
                }
                else {
                    let filterValue = userInput.value[displayField.value] ?? userInput.value
                    filteredSuggestions.value = listOfSuggestions.value.filter((suggestion) => {
                        return String(suggestion[displayField.value]).toLowerCase().startsWith(filterValue.toLowerCase());
                    });
                }
            }, 250);
        };

        return { 
            filterSuggestions,
            filteredSuggestions,
            userInput
        };
    }
}
</script>

<style scoped>

</style>