import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { LoginCallback, navigationGuard } from '@okta/okta-vue';
import { hasWriteAccess } from '../Okta/oktaHandler';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/update',
    name: 'Update',
    component: () => import(/* webpackChunkName: "about" */ '../views/Update.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (from.name === 'Home') {
        if (await hasWriteAccess()) {
          next();
        }
        else {
          next({ name: 'NoAccess' });
        }
      }
      else {
        next({ name: 'Home' });
      }
    }
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import(/* webpackChunkName: "about" */ '../views/Create.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      if (await hasWriteAccess()) {
        next();
      }
      else {
        next({ name: 'NoAccess' });
      }
    }
  },
  {
    path: '/details',
    name: 'Details',
    component: () => import(/* webpackChunkName: "about" */ '../views/Details.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const imageData = store.getters["categoryImages/getImageData"];
      if (imageData) {
        next();
      }
      else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/noaccess',
    name: 'NoAccess',
    component: () => import(/* webpackChunkName: "about" */ '../views/NoAccess.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});
router.beforeEach(navigationGuard);

export default router;
