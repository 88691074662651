import toastHandler from "../helpers/toastHandler";
import { validateApiUrl, getApiClient } from "./AxiosBase";

/*
Azure Static Web App task doesn't currently support passing environment variables
from the pipeline. So we're using token replacement to control the URL from the pipeline
during deployment on Azure.
*/
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL ?? "#{VUE_APP_API_BASE_URL}#";
const apiSubscriptionKey = process.env.VUE_APP_API_SUBSCRIPTION_KEY ?? "#{VUE_APP_API_SUBSCRIPTION_KEY}#";

const { displayErrorToast } = toastHandler();
if (!validateApiUrl(apiBaseUrl)) {
    displayErrorToast("Invalid API URL", `The provided API URL is invalid: ${apiBaseUrl}`);
}

const catImagesApiClient = getApiClient(apiBaseUrl, "Ocp-Apim-Subscription-Key", apiSubscriptionKey);

catImagesApiClient.interceptors.response.use(response => {
    return response;
}, error => {

    const title = "API Operation Failed"
    const message = error.response.data && error.response.data.message ? error.response.data.message :
        "Something went wrong. A detailed error log has been written on the console.";

    console.log(error.response.data);
    displayErrorToast(title, message, 5000);

    return Promise.reject(error.response);
});

export { catImagesApiClient };