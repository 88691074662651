import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toastification from "vue-toastification";
import "vue-toastification/dist/index.css";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import OktaVue from '@okta/okta-vue';
import { oktaAuthClient } from './Okta/oktaHandler';

createApp(App)
    .use(PrimeVue)
    .use(store)
    .use(router)
    .use(Toastification)
    .use(OktaVue, {
        oktaAuth: oktaAuthClient,
        onAuthRequired: (oktaAuthClient) => {
            oktaAuthClient.signInWithRedirect()
        }
    })
    .mount('#app');
