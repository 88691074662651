<template>
  <BaseAutoCompleteDropDown
    :listOfSuggestions="suggestions"
    displayField="brandName"
    placeHolderText="Select a Brand"
    v-model="selectedBrand"
    @dropdown-selected="brandSelected"
  />
</template>

<script>
import BaseAutoCompleteDropDown from "../base/BaseAutoCompleteDropDown.vue";
import AxiosService from "../../api/AxiosService";
import { ref } from "vue";
import { useStore } from "vuex";
import ToastHandler from "../../helpers/toastHandler";

export default {
  name: "BrandsAutoCompleteDropdown",
  components: {
    BaseAutoCompleteDropDown,
  },
  setup(props, {emit}) {

    const { displayInfoToast } = ToastHandler();
    const store = useStore();
    let suggestions = ref([]);
    
    const getBrands = async () => {
      suggestions.value = await AxiosService.getAllBrands();
    };

    const brandSelected = async () => {
      store.commit("categoryImages/setSelectedBrand", selectedBrand.value);
      const categories = await AxiosService.getCategories(
        store.getters["categoryImages/getSelectedBrand"].brandName
      );
      store.commit("categoryImages/setCategories", categories);

      //If categories are empty, notify the user.
      if(categories.length === 0){
        displayInfoToast(
          "Categories Not Found",
          "No categories were found for " + selectedBrand.value.brandName + "."
        );
      }

      emit('brand-selected', selectedBrand.value);
    };

    const selectedBrand = ref(null);

    getBrands();
    return { suggestions, selectedBrand, brandSelected };
  },
};
</script>
