import { OktaAuth } from '@okta/okta-auth-js';

const oktaIssuer = process.env.VUE_APP_OKTA_ISSUER ?? "#{VUE_APP_OKTA_ISSUER}#";
const clientId = process.env.VUE_APP_OKTA_CLIENT_ID ?? "#{VUE_APP_OKTA_CLIENT_ID}#";

const oktaAuthClient = new OktaAuth({
    issuer: oktaIssuer,
    clientId: clientId,
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email', 'groups']
});

oktaAuthClient.tokenManager.on('error', (error) => {
    console.log(error);
    oktaAuthClient.signInWithRedirect();
});

const getCurrentUserGroups = async () => {
    let userGroups = [];
    if (oktaAuthClient.token) {
        const user = await oktaAuthClient.getUser();
        userGroups = user.groups ?? [];
    }
    return userGroups;
};

const hasWriteAccess = async (groups) => {
    const enforceAccessGroups = process.env.VUE_APP_ENFORCE_ACCESS_GROUPS ?? "#{VUE_APP_ENFORCE_ACCESS_GROUPS}#";
    if (enforceAccessGroups.toLowerCase() === "true") {
        const writeGroup = process.env.VUE_APP_RW_GROUP_NAME ?? "#{VUE_APP_RW_GROUP_NAME}#";
        let userGroups = []
        if(!groups || !(groups.length > 0)){
            userGroups = await getCurrentUserGroups();
        }
        else{
            userGroups = groups;
        }
        return userGroups.includes(writeGroup);
    }
    else {
        return true;
    }
};

export { oktaAuthClient, getCurrentUserGroups, hasWriteAccess };