import { validateApiUrl, getApiClient } from "./AxiosBase";

/*
Azure Static Web App task doesn't currently support passing environment variables
from the pipeline. So we're using token replacement to control the URL from the pipeline
during deployment on Azure.
*/
const apiBaseUrl = process.env.VUE_APP_HEADER_API_BASE_URL ?? "#{VUE_APP_HEADER_API_BASE_URL}#";
const apiSubscriptionKey = process.env.VUE_APP_HEADER_API_SUBSCRIPTION_KEY ?? "#{VUE_APP_HEADER_API_SUBSCRIPTION_KEY}#";

validateApiUrl(apiBaseUrl);
const headerApiClient = getApiClient(apiBaseUrl, "Ocp-Apim-Subscription-Key", apiSubscriptionKey);

export { headerApiClient };