<template>
  <BaseAutoCompleteDropDown
    :listOfSuggestions="suggestions"
    displayField="categoryName"
    placeHolderText="Select a Category"
    v-model="selectedCategory"
    @dropdown-selected="categorySelected"
  />
</template>

<script>
import BaseAutoCompleteDropDown from "../base/BaseAutoCompleteDropDown.vue";
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "CategoriesAutoCompleteDropdown",
  components: {
    BaseAutoCompleteDropDown,
  },
  setup(props, { emit }) {
    const store = useStore();
    const suggestions = ref(
      store.getters["categoryImages/getCategories"] || []
    );
    const selectedCategory = ref(null);

    store.watch(
      () => store.getters["categoryImages/getCategories"],
      () => {
        suggestions.value = store.getters["categoryImages/getCategories"];
      }
    );

    const categorySelected = () => {
      store.commit(
        "categoryImages/setSelectedCategory",
        selectedCategory.value
      );
      emit('category-selected', selectedCategory.value);
    };

    return { suggestions, selectedCategory, categorySelected };
  },
};
</script>