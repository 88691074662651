<template>
  <div>
    <div class="p-grid">
      <Loading :is-loading="isLoading" />
      <div class="p-col-7 p-offset-3">
        <div class="brand-div">
          <label>Brand</label>
          <BrandDropdown
            @brand-selected="clearCategory"
            v-model="selectedBrand"
          />
        </div>
        <div class="category-div">
          <label>Categories</label>
          <CategoriesDropdown v-model="selectedCategory" />
        </div>
        <div style="float: left">
          <BaseButton @click="getImages" name="Search" />
        </div>
      </div>
    </div>
    <ImagePanel v-if="images.length > 0" :image-array="images" />
  </div>
</template>

<script>
// @ is an alias to /src

import BrandDropdown from "../components/BrandsAndCategories/BrandsAutoCompleteDropdown.vue";
import CategoriesDropdown from "../components/BrandsAndCategories/CategoriesAutoCompleteDropdown.vue";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import ImagePanel from "../components/categoryImages/CategoryImagePanel.vue";
import AxiosService from "../api/AxiosService";
import BaseButton from "../components/base/BaseButton.vue";
import "primeflex/primeflex.css";
import ToastHandler from "../helpers/toastHandler";
import Loading from "../components/base/Loading.vue";

export default {
  name: "Home",
  components: {
    BrandDropdown,
    CategoriesDropdown,
    ImagePanel,
    BaseButton,
    Loading,
  },
  setup() {
    const store = useStore();
    const { displayInfoToast } = ToastHandler();
    const selectedBrand = ref(store.getters["categoryImages/getSelectedBrand"]);
    const selectedCategory = ref(
      store.getters["categoryImages/getSelectedCategory"]
    );
    const images = ref(store.getters["categoryImages/getImages"]);

    const isLoading = ref(false);
    const getImages = async () => {
      try {
        isLoading.value = true;
        const data = await AxiosService.getImages(
          selectedCategory.value.categoryName,
          selectedBrand.value.brandName
        );

        if (data.length === 0) {
          //Notify the user that there were no images found.
          displayInfoToast(
            "Images Not Found",
            `No images were found for ${selectedCategory.value.categoryName}.`
          );

          //Update the UI and the store .
          images.value = [];
          store.commit("categoryImages/setImages", []);
        } else {
          store.commit("categoryImages/setImages", data);
          images.value = store.getters["categoryImages/getImages"];
        }
      } finally {
        isLoading.value = false;
      }
    };

    const clearCategory = () => {
      selectedCategory.value = null;
    };

    return {
      selectedBrand,
      selectedCategory,
      images,
      isLoading,
      getImages,
      clearCategory,
    };
  },
};
</script>

<style scoped>
.brand-div {
  float: left;
  margin-right: 25px;
}
.brand-div label {
  margin-right: 5px;
}

.category-div {
  float: left;
  margin-right: 25px;
}
.category-div label {
  margin-right: 5px;
}
</style>